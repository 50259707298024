// routesConfig.tsx
import { lazy } from "react";

// project import
import Loadable from "../components/Loadable";
import { RouteConfig } from "./types";

import { Role } from "../utils/enums/roles.enum";
import { Route } from "../utils/Route";

const Layout = Loadable(lazy(() => import("../components/layout")));
const Dashboard = Loadable(lazy(() => import("../pages/dashboard")));
const CreateEditViewOrgainization = Loadable(
  lazy(() => import("../pages/common/organization/create-edit-view"))
);
const ListOrgainization = Loadable(
  lazy(() => import("../pages/common/organization/list-organizations"))
);
const ListUsers = Loadable(
  lazy(() => import("../pages/common/user/list-users"))
);
const ListAssessments = Loadable(
  lazy(() => import("../pages/common/assessments/list-assessments"))
);
const ListCohorts = Loadable(
  lazy(() => import("../pages/common/cohorts/list-cohorts"))
);
const ListParticipants = Loadable(
  lazy(() => import("../pages/common/participant/list-participant"))
);
const ListOrders = Loadable(
  lazy(() => import("../pages/common/orders/list-orders"))
);
const CreateEditViewParticipant = Loadable(
  lazy(() => import("../pages/common/participant/create-view-edit"))
);
const CreateEditViewAssessment = Loadable(
  lazy(() => import("../pages/common/assessments/create-edit-view"))
);
const CreateEditViewCohort = Loadable(
  lazy(() => import("../pages/common/cohorts/create-edit-view"))
);
const CreateEditViewOrder = Loadable(
  lazy(() => import("../pages/common/orders/create-edit-view"))
);

export const routesConfig: RouteConfig = {
  path: "/",
  element: <Layout />,
  children: [
    {
      path: Route.DASHBOARD,
      element: <Dashboard />,
      roles: [
        Role.PLATFORM_OWNER,
        Role.SUPER_ADMIN,
        Role.ORGANIZATION_ADMIN,
        Role.ADMIN,
      ],
    },
    {
      path: Route.ORGANIZATION,
      element: <CreateEditViewOrgainization />,
      roles: [Role.PLATFORM_OWNER],
    },
    {
      path: Route.LIST_ORGANIZATION,
      element: <ListOrgainization />,
      roles: [Role.PLATFORM_OWNER],
    },
    {
      path: Route.LIST_USER,
      element: <ListUsers />,
      roles: [
        Role.PLATFORM_OWNER,
        Role.SUPER_ADMIN,
        Role.ORGANIZATION_ADMIN,
        Role.ADMIN,
      ],
    },
    {
      path: Route.ASSESSMENT,
      element: <CreateEditViewAssessment />,
      roles: [Role.SUPER_ADMIN, Role.ORGANIZATION_ADMIN],
    },
    {
      path: Route.COHORT,
      element: <CreateEditViewCohort />,
      roles: [Role.SUPER_ADMIN, Role.ORGANIZATION_ADMIN],
    },

    {
      path: Route.LIST_ASSESSMENT,
      element: <ListAssessments />,
      roles: [Role.SUPER_ADMIN, Role.ORGANIZATION_ADMIN],
    },
    {
      path: Route.LIST_COHORT,
      element: <ListCohorts />,
      roles: [Role.SUPER_ADMIN, Role.ORGANIZATION_ADMIN],
    },
    {
      path: Route.PARTICIPANT,
      element: <CreateEditViewParticipant />,
      roles: [Role.SUPER_ADMIN, Role.ORGANIZATION_ADMIN, Role.ADMIN],
    },
    {
      path: Route.ORDER,
      element: <CreateEditViewOrder />,
      roles: [Role.SUPER_ADMIN, Role.ORGANIZATION_ADMIN, Role.ADMIN],
    },
    {
      path: Route.LIST_PARTICIPANT,
      element: <ListParticipants />,
      roles: [Role.SUPER_ADMIN, Role.ORGANIZATION_ADMIN, Role.ADMIN],
    },
    {
      path: Route.LIST_ORDER,
      element: <ListOrders />,
      roles: [Role.SUPER_ADMIN, Role.ORGANIZATION_ADMIN, Role.ADMIN],
    },
  ],
};
