import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface LoginProps {
  isAuthenticated: boolean;
}

const initialState: LoginProps = {
  isAuthenticated: false,
};

export const AuthReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    auth: (state, action: PayloadAction<LoginProps>) => action.payload,
    logout: (state) => initialState,
  },
});

export const { auth, logout } = AuthReducer.actions;
export default AuthReducer.reducer;
