import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../api/user/user";

const initialState: User = {
  ameyaId: "",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  country: "",
  region: "",
  state: "",
  city: "",
  title: "",
  userRoles: [],
};

export const UserReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    user: (state, action: PayloadAction<User>) => action.payload,
  },
});

export const { user } = UserReducer.actions;
export default UserReducer.reducer;
