import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserRoles } from "../api/user/user";
import { Role } from "../utils/enums/roles.enum";

const initialState: UserRoles = {
  role: Role.ADMIN,
  canListParticipant: false,
  canCreateParticipant: false,
  canUpdateParticipant: false,
  canDeleteParticipant: false,
  canListAssessment: false,
  canCreateAssessment: false,
  canUpdateAssessment: false,
  canDeleteAssessment: false,
  canListUser: false,
  canCreateUser: false,
  canUpdateUser: false,
  canDeleteUser: false,
  organization: null,
};

export const PermissionReducer = createSlice({
  name: "permission",
  initialState,
  reducers: {
    permission: (state, action: PayloadAction<UserRoles>) => action.payload,
    platformOwnerAccess: (state) => {
      state.role = Role.PLATFORM_OWNER;
      state.canListParticipant = true;
      state.canCreateParticipant = true;
      state.canUpdateParticipant = true;
      state.canDeleteParticipant = true;
      state.canListAssessment = true;
      state.canCreateAssessment = true;
      state.canUpdateAssessment = true;
      state.canDeleteAssessment = true;
      state.canListUser = true;
      state.canCreateUser = true;
      state.canUpdateUser = true;
      state.canDeleteUser = true;
    },
  },
});

export const { permission, platformOwnerAccess } = PermissionReducer.actions;
export default PermissionReducer.reducer;
