const palette = {
  common: {
    black: "#000",
    white: "#fff",
  },
  primary: {
    light: "#E9F0FC",
    main: "#3B3B69",
    dark: "#3B3B69",
  },
  secondary: {
    light: "#F9E8E7",
    main: "#DB7670",
    dark: "#1F1769",
  },
  table: {
    light: "#E9F0FC",
    dark: "#1F1769",
  },
  text: {
    secondary: "#333333",
  },
  action: {
    active: "#3B3B69",
  },
  error: {
    light: "#ef9a9a",
    main: "#f44336",
    dark: "#c62828",
  },
  warning: {
    light: "#fff8e1",
    main: "#ffe57f",
    dark: "#ffc107",
  },
  info: {
    light: "#8397FF",
    main: "#475CC7",
    dark: "#505EAC",
  },
  success: {
    light: "#b9f6ca",
    main: "#00e676",
    dark: "#00c853",
  },
  background: {
    paper: "#ffffff",
    default: "#ffffff",
  },
  grey: {
    50: "#D0D5DD",
    100: "#888",
    200: "#E3E8EF",
    300: "#CDD5DF",
    500: "#596273",
    600: "#475467",
    700: "#364152",
    900: "#121926",
  },
};

export default palette;
