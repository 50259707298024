import { configureStore } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import AuthReducer, { LoginProps } from "./AuthReducer";
import NotifyReducer, { setAlert } from "./NotifyReducer";
import AppbarReducer from "./AppbarReducer";
import DrawerReducer from "./DrawerReducer";
import UserReducer from "./UserReducer";
import UserRoleReducer from "./UserRoleReducer";
import OrganizationReducer from "./OrganizationReducer";
import PermissionReducer from "./PermissionReducer";

const loadAuthState = (): LoginProps => {
  const idToken = sessionStorage.getItem("idToken");
  if (idToken) {
    const decodedToken: any = jwtDecode(idToken);
    const exp = decodedToken.exp;
    const currentTime = Math.floor(Date.now() / 1000);

    if (exp > currentTime) {
      return { isAuthenticated: true };
    }
  }
  sessionStorage.clear();
  return { isAuthenticated: false };
};

export const store = configureStore({
  reducer: {
    appbar: AppbarReducer,
    auth: AuthReducer,
    user: UserReducer,
    organization: OrganizationReducer,
    notify: NotifyReducer,
    drawer: DrawerReducer,
    role: UserRoleReducer,
    permission: PermissionReducer,
  },
  preloadedState: {
    auth: loadAuthState(),
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
