export enum Role {
  PLATFORM_OWNER = "PLATFORM_OWNER",
  SUPER_ADMIN = "SUPER_ADMIN",
  ORGANIZATION_ADMIN = "ORGANIZATION_ADMIN",
  ADMIN = "ADMIN",
  PARTICIPANT = "PARTICIPANT",
}

export const roleLabels = {
  [Role.PLATFORM_OWNER]: "Platform Owner",
  [Role.SUPER_ADMIN]: "Super Admin",
  [Role.ORGANIZATION_ADMIN]: "Organization Admin",
  [Role.ADMIN]: "Admin",
  [Role.PARTICIPANT]: "Participant",
};
