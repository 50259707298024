import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AppBarProps {
  tabValue: number;
  isUnSaved?: boolean;
}

const initialState: AppBarProps = {
  tabValue: 0,
  isUnSaved: false,
};

export const AppbarReducer = createSlice({
  name: "appbar",
  initialState,
  reducers: {
    appbarToggle: (state, action: PayloadAction<AppBarProps>) => action.payload,
    resetToggle: (state) => initialState,
  },
});

export const { appbarToggle, resetToggle } = AppbarReducer.actions;
export default AppbarReducer.reducer;
