// RouteGuard.tsx
import React from "react";
import { Navigate } from "react-router-dom";
import { Role } from "../utils/enums/roles.enum";
import { useAppSelector } from "../reduxjs/hooks";
import { Route } from "../utils/Route";
import Loader from "../components/Loader";

interface RouteGuardProps {
  children: React.ReactNode;
  allowedRoles: string[];
}

const RouteGuard: React.FC<RouteGuardProps> = ({
  children,
  allowedRoles = [],
}) => {
  const authState = useAppSelector((state) => state.auth);
  const roleState = useAppSelector((state) => state.role);

  if (!authState.isAuthenticated) {
    return <Navigate to={Route.WELCOME} />;
  }

  if (!roleState.role) {
    return <Loader />;
  }

  if (!allowedRoles.includes(roleState.role as Role)) {
    return <Navigate to={Route.UNAUTHORIZED} />;
  }

  return <>{children}</>;
};

export default RouteGuard;
