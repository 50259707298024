import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Role } from "../utils/enums/roles.enum";

export interface UserRole {
  role: Role | null;
}

const initialState: UserRole = {
  role: null,
};

export const UserRoleReducer = createSlice({
  name: "userRole",
  initialState,
  reducers: {
    role: (state, action: PayloadAction<UserRole>) => action.payload,
  },
});

export const { role } = UserRoleReducer.actions;
export default UserRoleReducer.reducer;
