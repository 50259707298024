import {
  Snackbar,
  Alert,
  SnackbarCloseReason,
  Typography,
} from "@mui/material";
import { CustomSnackbarProps, setAlert } from "../../../reduxjs/NotifyReducer";
import { useAppDispatch } from "../../../reduxjs/hooks";

const CustomSnackbar = ({
  notifyState,
  ...props
}: {
  notifyState: CustomSnackbarProps;
}) => {
  const dispatch = useAppDispatch();

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(setAlert({ ...notifyState, open: false }));
  };

  return (
    <Snackbar
      anchorOrigin={
        notifyState.anchorOrigin
          ? notifyState.anchorOrigin
          : { vertical: "top", horizontal: "center" }
      }
      open={notifyState.open}
      autoHideDuration={4000}
      onClose={handleClose}
      {...props}
    >
      <Alert
        onClose={handleClose}
        severity={notifyState.severity}
        variant={notifyState.variant}
        sx={{ width: "100%" }}
      >
        <Typography variant="body1">{notifyState.message}</Typography>
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
