import { BrowserRouter } from "react-router-dom";
import ThemeCustomization from "./themes";
import RenderRoutes from "./routes/RenderRoutes";
import { routesConfig } from "./routes/routesConfig";

export default function App() {
  return (
    <ThemeCustomization>
      <BrowserRouter>
        <RenderRoutes routes={routesConfig} />
      </BrowserRouter>
    </ThemeCustomization>
  );
}
