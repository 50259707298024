import React from "react";
import { Typography, Container, IconButton, Button, Box } from "@mui/material";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { useNavigate } from "react-router-dom";

const NoPageFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          width: 500,
          height: 200,
          backgroundColor: "white",
          textAlign: "center",
          borderRadius: "10px",
          position: "absolute",
          left: "50%",
          right: "50%",
          top: "50%",
          transform: " translate(-50%, -50%)",
          padding: 5,
        }}
      >
        <Typography variant="h6">Page Not Found</Typography>
        <IconButton>
          <DoNotDisturbIcon sx={{ fontSize: "60px", color: "orangered" }} />
        </IconButton>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate(-1)}
          >
            Go to Home page
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default NoPageFound;
