import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DrawerProps {
  open: boolean;
  id?: string;
  trigger?: boolean;
}

const initialState: DrawerProps = {
  open: false,
  trigger: false,
};

export const DrawerReducer = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    openDrawer: (state, action: PayloadAction<DrawerProps>) => action.payload,
    closeDrawer: (state, action: PayloadAction<{ trigger?: boolean }>) => {
      return {
        ...initialState,
        trigger: action.payload.trigger || false,
      };
    },
  },
});

export const { openDrawer, closeDrawer } = DrawerReducer.actions;
export default DrawerReducer.reducer;
